import request from '@/utils/request'


// 查询新-用户APP样式列表
export function listStyle(query) {
  return request({
    url: '/cigarette/user-style/list',
    method: 'get',
    params: query
  })
}

// 查询新-用户APP样式分页
export function pageStyle(query) {
  return request({
    url: '/cigarette/user-style/page',
    method: 'get',
    params: query
  })
}

// 查询新-用户APP样式详细
export function getStyle(data) {
  return request({
    url: '/cigarette/user-style/detail',
    method: 'get',
    params: data
  })
}

// 新增新-用户APP样式
export function addStyle(data) {
  return request({
    url: '/cigarette/user-style/add',
    method: 'post',
    data: data
  })
}

// 修改新-用户APP样式
export function updateStyle(data) {
  return request({
    url: '/cigarette/user-style/edit',
    method: 'post',
    data: data
  })
}

// 删除新-用户APP样式
export function delStyle(data) {
  return request({
    url: '/cigarette/user-style/delete',
    method: 'post',
    data: data
  })
}
